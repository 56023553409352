import React, { useState, useEffect } from 'react'
import logoimage from '../../Images/Hajj/hajj_page_logo.jpeg'
import kabahpic from '../../Images/Hajj/3.jpeg'
import logo from '../../Images/Logo/logo.png'
import Axios from 'axios'
import Select from 'react-select'
import Loading from '../../Components/Loading/Loader'
import { ToastContainer, toast } from 'react-toastify'
import Layout from '../../Components/Layout/Layout'
import { useNavigate } from 'react-router-dom'
import {
  HajjApiToken,
  ApiEndPoint
} from '../../Components/GlobalData/GlobalData'
import emailjs from 'emailjs-com'

import circle from '../../Images/Hajj/circle.svg'
import user from '../../Images/Hajj/user-add.svg'
import file from '../../Images/Hajj/file.svg'
import clipboard from '../../Images/Hajj/clipboard.svg'
import favourite from '../../Images/Hajj/favourite.svg'
import check from '../../Images/Hajj/check.svg'
import thumb from '../../Images/Hajj/thumb.svg'
import calandar from '../../Images/Hajj/calendar.svg'
import fvfile from '../../Images/Hajj/favourite-file.svg'
import payment from '../../Images/Hajj/payment.svg'
import HajjTop from './HajjTop'
function HajjDetail () {
  const navigation = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    fname: '',
    lname: '',
    passenger: '',
    phnno: '',
    address: '',
    city: '',
    postode: ''
  })
  const [countryList, setCountryList] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleCountryChange = selectedOption => {
    setSelectedCountry(selectedOption)
    setFormData(prevdata => ({
      ...prevdata,
      phnno: selectedOption.phoneCode
    }))
  }

  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  }
  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const submitFormData = async () => {
    if (formData.email === '') {
      toast.info('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.fname === '') {
      toast.info('Please Enter Your First Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.lname === '') {
      toast.info('Please Enter Your Last Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (selectedCountry === null) {
      toast.info('Please Select Country.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.passenger === '') {
      toast.info('Please Enter Passenger.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.phnno === '') {
      toast.info('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.city === '') {
      toast.info('Please Enter City.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(formData.email)) {
      toast.info('Please Enter a valid email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }
    const endpoint = ApiEndPoint()
    const token = HajjApiToken()
    setIsLoading(true)
    var data = {
      token: token,
      email_Address: formData.email,
      first_Name: formData.fname,
      last_Name: formData.lname,
      no_Of_Passengers: formData.passenger,
      phone_No: formData.phnno,
      street_Address: formData.address,
      city: formData.city,
      post_Code: formData.postode,
      country: selectedCountry.value
    }
    try {
      const response = await Axios.post(
        endpoint + '/api/create_ShowInterest',
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      setIsLoading(false)
      if (response.data.status === 'success') {
        navigation('/hajj-confirmation')
      } else if (response.data.status === 'error') {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } catch (error) {
      setIsLoading(false)
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData () {
    Axios.get(
      'https://restcountries.com/v3.1/all?fields=name,flags,cca2,cca3,idd'
    )
      .then(response => {
        const countryOptions = response?.data.map(country => ({
          value: country.name.common,
          label: country.name.common,
          flag: country.flags.png,
          phoneCode: country.idd.root + country.idd.suffixes[0]
        }))
        setCountryList(countryOptions)
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }

  return (
    <>
      {isLoading && <Loading />}
      <ToastContainer />
      <Layout>
        <HajjTop />
        {/* <div className='hajj-top'>
          <img src={bgimage} />
          <div className='hajj-title'>
            <div className='text-center mb-4'>
              <h1
                class='font_0 wixui-rich-text__text'
                style={{ fontSize: '65px', lineHeight: '0.9em' }}
              >
                <span className='hajj-h-1 text-white'>HAJJ</span>{' '}
                <span className='hajj-h-2 '>2025</span>
              </h1>
              <div
                style={{ flexWrap: 'wrap' }}
                className='d-flex  hajj-links-2 justify-content-center align-items-center'
              >
                <div>
                  <a
                    class='nav-link '
                    href='https://chat.whatsapp.com/IfJtMNZQXerDgcltof7HGy'
                    target='blank'
                  >
                    <svg
                      fill='#279b3e'
                      height='18px'
                      width='18px'
                      viewBox='0 0 308 308'
                      stroke='#279b3e'
                    >
                      <g>
                        <path
                          d='M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                    c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                    c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                    c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                    c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                    c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                    c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                    c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                    c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                    C233.168,179.508,230.845,178.393,227.904,176.981z'
                        />
                        <path
                          d='M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                    c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                    c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                    l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                    c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                    C276.546,215.678,222.799,268.994,156.734,268.994z'
                        />
                      </g>
                    </svg>{' '}
                    JOIN HAJJ WHATSAPP GROUP
                  </a>
                </div>
                <div>
                  <a
                    class='nav-link '
                    href='https://t.me/+tF5MQ2KCCCU1Y2Zk'
                    target='_blank'
                  >
                    <svg
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#2180ba'
                      height='18px'
                      width='18px'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M23.1117 4.49449C23.4296 2.94472 21.9074 1.65683 20.4317 2.227L2.3425 9.21601C0.694517 9.85273 0.621087 12.1572 2.22518 12.8975L6.1645 14.7157L8.03849 21.2746C8.13583 21.6153 8.40618 21.8791 8.74917 21.968C9.09216 22.0568 9.45658 21.9576 9.70712 21.707L12.5938 18.8203L16.6375 21.8531C17.8113 22.7334 19.5019 22.0922 19.7967 20.6549L23.1117 4.49449ZM3.0633 11.0816L21.1525 4.0926L17.8375 20.2531L13.1 16.6999C12.7019 16.4013 12.1448 16.4409 11.7929 16.7928L10.5565 18.0292L10.928 15.9861L18.2071 8.70703C18.5614 8.35278 18.5988 7.79106 18.2947 7.39293C17.9906 6.99479 17.4389 6.88312 17.0039 7.13168L6.95124 12.876L3.0633 11.0816ZM8.17695 14.4791L8.78333 16.6015L9.01614 15.321C9.05253 15.1209 9.14908 14.9366 9.29291 14.7928L11.5128 12.573L8.17695 14.4791Z'
                        fill='#2d7fbe'
                      />
                    </svg>{' '}
                    JOIN HAJJ TELEGRAM CHANNEL
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div> */}
        <div className='container mt-3'>
          <div className='text-center my-2'>
            <h3>Hajj 2025 – Register Your Interest</h3>
          </div>
          <div className='row mt-4'>
            <div className='col-md-7'>
              <div className=''>
                <h5>Update for Hajj 2025/1446AH</h5>
                <p className='mt-3'>
                  We are pleased to announce that Sidra Tours partnered with
                  MCDC to successfully facilitate Hajj 1446AH/2025 for pilgrims
                  from the UK in line with new guidelines and processes from the
                  Ministry of Hajj and Nusuk Portal.
                </p>
                <p className='mt-3'>
                  We were extremely honored to provide support and guidance to
                  UK Pilgrims as a Marketing Partner. For 2025, we await details
                  on the Hajj procedure from UK. We expect it to remain under
                  the Nusuk Portal. There is no payment to be made at this
                  stage.
                </p>
                <p className='mt-3'>
                  Register your interest in Hajj 2025 to stay up to date with
                  package information and the booking process.
                </p>
                {/* <p className="fw-bold">Al Hijaz Tours Ltd is the marketing partner of MCDC, Hajj Nusuk 2024 for Nusuk approved Hajj companies.</p> */}
                <a
                  target='_blank'
                  href='https://hajj.nusuk.sa/registration/signup'
                >
                  <button className='btn btn-success mt-3'>
                    Click to login Nusuk
                  </button>
                </a>
              </div>
              <div className='row mt-5'>
                <div className='form-group col-md-6 mt-2'>
                  <label>Email address:</label>
                  <input
                    type='email'
                    onChange={handleChange}
                    value={formData.email}
                    name='email'
                    className='form-control mt-2'
                    placeholder='email address'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>First Name:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.fname}
                    name='fname'
                    className='form-control mt-2'
                    placeholder='First Name'
                  />
                </div>
                <div className='form-group col-md-6 mt-2 '>
                  <label>Last Name:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.lname}
                    name='lname'
                    className='form-control mt-2'
                    placeholder='Last Name'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>City:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.city}
                    name='city'
                    className='form-control mt-2'
                    placeholder='City'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>Country:</label>
                  <Select
                    options={countryList}
                    isSearchable={true}
                    className='mt-2'
                    onChange={handleCountryChange}
                    value={selectedCountry}
                    getOptionLabel={option => (
                      <div>
                        <img
                          src={option.flag}
                          alt={option.label}
                          style={{ width: '20px', marginRight: '8px' }}
                        />
                        {option.label} ({option.phoneCode})
                      </div>
                    )}
                    getOptionValue={option => option.value}
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>Phone Number:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.phnno}
                    name='phnno'
                    className='form-control mt-2'
                    placeholder='Number'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>Number Of Passengers:</label>
                  <input
                    type='number'
                    onChange={handleChange}
                    value={formData.passenger}
                    name='passenger'
                    className='form-control mt-2'
                    placeholder='Passengers'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>Street Address:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.address}
                    name='address'
                    className='form-control mt-2'
                    placeholder='Street Address'
                  />
                </div>
                <div className='form-group col-md-6 mt-2'>
                  <label>Post Code:</label>
                  <input
                    type='text'
                    onChange={handleChange}
                    value={formData.postode}
                    name='postode'
                    className='form-control mt-2'
                    placeholder='Post Code'
                  />
                </div>
              </div>
              <div className='row mt-2'>
                <div className='form-group col-md-5 mt-2'>
                  <button
                    onClick={submitFormData}
                    class='btn btn-primary btn-block select-styling search-btn1'
                  >
                    Register Your Interest
                  </button>
                </div>
              </div>
            </div>
            <div className='hajj_Pic col-md-5'>
              {/* <div className='p-3'>
                <img src={kabahpic} />
                <div className='hajj-img'>
                  <p>Leading Hajj & Umrah Provider</p>
                  <img src={logo} />
                </div>
              </div> */}
              <section className='hajj_section_new mt-4'>
                <div className='text-center'>
                  <h4 className='text-black'>
                    In addition, here are the key dates announced by the
                    Ministry of Hajj for the upcoming season:
                  </h4>
                  <p>
                    (We recommend keeping these dates in mind as you prepare for
                    your journey. However, these dates are provisional and
                    subject to change)
                  </p>
                </div>
                <div className='row htt555'>
                  <div className='col-md-12 hajj_page_date col-sm-6 col-12 text-center px-4'>
                    <div>
                      <svg
                        fill='#ce9a01'
                        preserveAspectRatio='xMidYMid meet'
                        data-bbox='22.998 37 154.002 126.001'
                        viewBox='22.998 37 154.002 126.001'
                        height='90'
                        width='90'
                        data-type='shape'
                        role='presentation'
                        aria-hidden='true'
                        aria-label=''
                      >
                        <g>
                          <path d='M175.479 123.465c-2.799 9.437-9.149 17.226-17.88 21.932a4.006 4.006 0 0 1-1.905.483 4.002 4.002 0 0 1-3.518-2.075 3.951 3.951 0 0 1 1.605-5.381c6.85-3.693 11.83-9.8 14.024-17.199 2.188-7.375 1.344-15.154-2.378-21.903-5.11-9.267-14.917-15.028-25.596-15.035h-8.355a3.995 3.995 0 0 1-3.872-2.972c-6.771-25.9-33.596-41.523-59.797-34.825C55.088 49.741 44.422 57.7 37.771 68.9c-6.618 11.147-8.461 24.177-5.189 36.691a48.53 48.53 0 0 0 10.736 19.978 3.949 3.949 0 0 1-.339 5.603 4.025 4.025 0 0 1-5.646-.336c-5.932-6.637-10.253-14.677-12.494-23.251-3.809-14.569-1.664-29.738 6.04-42.714 7.736-13.029 20.142-22.286 34.932-26.067 29.451-7.532 59.579 9.205 68.671 37.544h5.352c13.604.01 26.099 7.353 32.61 19.163 4.75 8.613 5.828 18.541 3.035 27.954zm-72.617-26.273-.005-.005-.002-.002c-.013-.012-.028-.021-.041-.033a4.02 4.02 0 0 0-.566-.458c-.104-.069-.216-.117-.325-.175-.12-.064-.234-.136-.361-.188-.143-.059-.291-.094-.439-.135-.103-.029-.2-.069-.306-.09a4.066 4.066 0 0 0-1.57 0c-.106.021-.203.061-.305.09-.148.042-.296.077-.439.135-.126.052-.241.124-.36.188-.109.058-.222.106-.326.176a4.02 4.02 0 0 0-.566.458c-.013.012-.028.02-.041.033l-.002.002-.005.005-26.529 26.231a3.946 3.946 0 0 0-.01 5.612 4 4 0 0 0 2.833 1.167 3.998 3.998 0 0 0 2.823-1.158l19.714-19.492v49.479c0 2.192 1.791 3.969 3.999 3.969 2.209 0 3.999-1.777 3.999-3.969v-49.479l19.713 19.492a4.02 4.02 0 0 0 5.655-.01 3.946 3.946 0 0 0-.01-5.612l-26.528-26.231z'></path>
                        </g>
                      </svg>
                    </div>
                    <h5 className='mt-3 text-black'>
                      OPENING of pilgrim registration on nusuk
                    </h5>
                    <p className='mt-4 text-black'>18 October 2024</p>
                  </div>
                  <div className='col-md-12 hajj_page_date col-sm-6 col-12 text-center px-4'>
                    <div>
                      <svg
                        fill='#ce9a01'
                        preserveAspectRatio='xMidYMid meet'
                        data-bbox='20 24 159.999 152.001'
                        viewBox='20 24 159.999 152.001'
                        height='90'
                        width='90'
                        data-type='shape'
                        role='presentation'
                        aria-hidden='true'
                        aria-label=''
                      >
                        <g>
                          <path d='M106.052 28.281l15.263 43.679a6.405 6.405 0 0 0 5.921 4.28l46.476.942c6.073.123 8.581 7.8 3.74 11.451l-37.043 27.937a6.355 6.355 0 0 0-2.262 6.924l13.461 44.261c1.759 5.784-4.806 10.529-9.792 7.077l-38.157-26.413a6.433 6.433 0 0 0-7.319 0l-38.157 26.413c-4.986 3.452-11.551-1.293-9.792-7.077l13.461-44.261a6.353 6.353 0 0 0-2.262-6.924L22.548 88.632c-4.841-3.651-2.333-11.328 3.74-11.451l46.476-.942a6.404 6.404 0 0 0 5.921-4.28L93.948 28.28c1.995-5.707 10.109-5.707 12.104.001z'></path>
                        </g>
                      </svg>
                    </div>
                    <h5 className='mt-3 text-black'>
                      VIEWING & FAVOURITING PACKAGES
                    </h5>
                    <p className='mt-4 text-black'>15-30 January 2025</p>
                  </div>
                  <div className='col-md-12 hajj_page_date col-sm-6 col-12 text-center px-4'>
                    <div>
                      <svg
                        fill='#ce9a01'
                        preserveAspectRatio='xMidYMid meet'
                        data-bbox='20 30.002 160.001 139.998'
                        viewBox='20 30.002 160.001 139.998'
                        height='90'
                        width='90'
                        data-type='color'
                        role='presentation'
                        aria-hidden='true'
                        aria-label=''
                      >
                        <defs></defs>
                        <g>
                          <path
                            d='M155.873 170H33.968C26.266 170 20 163.719 20 156V54.182c0-2.109 1.705-3.818 3.81-3.818s3.81 1.709 3.81 3.818c0 3.509 2.848 6.364 6.349 6.364h121.905c7.702 0 13.968 6.281 13.968 14V100a3.813 3.813 0 0 1-3.81 3.818 3.814 3.814 0 0 1-3.81-3.818V74.546c0-3.509-2.848-6.364-6.349-6.364H33.968c-2.285 0-4.443-.553-6.349-1.532V156c0 3.509 2.848 6.364 6.349 6.364h121.905c3.501 0 6.349-2.855 6.349-6.364v-25.455a3.813 3.813 0 0 1 3.81-3.818 3.814 3.814 0 0 1 3.81 3.818V156c-.001 7.719-6.267 14-13.969 14z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M23.81 58A3.813 3.813 0 0 1 20 54.182c0-7.719 6.266-14 13.968-14H94.92A3.814 3.814 0 0 1 98.73 44a3.813 3.813 0 0 1-3.81 3.818H33.968c-3.501 0-6.349 2.855-6.349 6.364A3.813 3.813 0 0 1 23.81 58z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M166.032 78.364a3.814 3.814 0 0 1-3.81-3.818V54.182c0-3.509-2.848-6.364-6.349-6.364a3.814 3.814 0 0 1-3.81-3.818 3.813 3.813 0 0 1 3.81-3.818c7.702 0 13.968 6.281 13.968 14v20.364a3.813 3.813 0 0 1-3.809 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M145.74 119.091h-.102a3.814 3.814 0 0 1-3.81-3.818 3.813 3.813 0 0 1 3.81-3.818h.102a3.814 3.814 0 0 1 3.81 3.818 3.815 3.815 0 0 1-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M54.289 68.183a3.81 3.81 0 0 1-3.41-2.111 3.823 3.823 0 0 1 1.703-5.123l60.952-30.545a3.807 3.807 0 0 1 5.111 1.707 3.823 3.823 0 0 1-1.703 5.123L55.99 67.779a3.794 3.794 0 0 1-1.701.404z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M130.479 68.183a3.81 3.81 0 0 1-3.41-2.111l-15.238-30.545a3.823 3.823 0 0 1 1.703-5.123 3.807 3.807 0 0 1 5.111 1.707l15.238 30.545a3.823 3.823 0 0 1-1.703 5.123 3.788 3.788 0 0 1-1.701.404z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M155.873 47.818h-35.556a3.814 3.814 0 0 1-3.81-3.818 3.813 3.813 0 0 1 3.81-3.818h35.556a3.814 3.814 0 0 1 3.81 3.818 3.814 3.814 0 0 1-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M135.556 134.364c-7.702 0-13.968-6.281-13.968-14a3.813 3.813 0 0 1 3.81-3.818 3.814 3.814 0 0 1 3.81 3.818c0 3.509 2.848 6.364 6.349 6.364 2.104 0 3.81 1.709 3.81 3.818s-1.707 3.818-3.811 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M125.397 114a3.814 3.814 0 0 1-3.81-3.818c0-7.719 6.266-14 13.968-14 2.104 0 3.81 1.709 3.81 3.818s-1.705 3.818-3.81 3.818c-3.501 0-6.349 2.855-6.349 6.364a3.813 3.813 0 0 1-3.809 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M125.397 124.182a3.814 3.814 0 0 1-3.81-3.818v-10.182a3.813 3.813 0 0 1 3.81-3.818 3.814 3.814 0 0 1 3.81 3.818v10.182a3.815 3.815 0 0 1-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M166.032 103.818h-30.476c-2.104 0-3.81-1.709-3.81-3.818s1.705-3.818 3.81-3.818h30.476c2.104 0 3.81 1.709 3.81 3.818s-1.706 3.818-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M166.032 134.364h-30.476c-2.104 0-3.81-1.709-3.81-3.818s1.705-3.818 3.81-3.818h30.476c2.104 0 3.81 1.709 3.81 3.818s-1.706 3.818-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M166.032 134.364c-2.104 0-3.81-1.709-3.81-3.818s1.705-3.818 3.81-3.818c3.501 0 6.349-2.855 6.349-6.364 0-2.109 1.705-3.818 3.81-3.818s3.81 1.709 3.81 3.818c-.001 7.719-6.267 14-13.969 14z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M176.19 114a3.814 3.814 0 0 1-3.81-3.818c0-3.509-2.848-6.364-6.349-6.364-2.104 0-3.81-1.709-3.81-3.818s1.705-3.818 3.81-3.818c7.702 0 13.968 6.281 13.968 14A3.812 3.812 0 0 1 176.19 114z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                          <path
                            d='M176.19 124.182a3.814 3.814 0 0 1-3.81-3.818v-10.182c0-2.109 1.705-3.818 3.81-3.818s3.81 1.709 3.81 3.818v10.182a3.813 3.813 0 0 1-3.81 3.818z'
                            fill='#ce9a01'
                            data-color='1'
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <h5 className='mt-3 text-black'>
                      TOPPING-UP DIGITAL WALLET
                    </h5>
                    <p className='mt-4 text-black'>15-30 January 2025</p>
                  </div>
                  <div className='col-md-12 hajj_page_date col-sm-6 col-12 text-center px-4'>
                    <div>
                      <svg
                        fill='#ce9a01'
                        preserveAspectRatio='xMidYMid meet'
                        data-bbox='42 20 116 160'
                        viewBox='42 20 116 160'
                        height='90'
                        width='90'
                        data-type='shape'
                        role='presentation'
                        aria-hidden='true'
                        aria-label=''
                      >
                        <g>
                          <path d='M157.992 174.116l-5.189-98.75c-.156-2.969-2.599-5.295-5.56-5.295h-13.97V52.34c0-17.832-14.451-32.34-32.215-32.34S68.844 34.508 68.844 52.34v17.73H52.756c-2.961 0-5.404 2.327-5.56 5.295l-5.188 98.75a5.597 5.597 0 0 0 1.521 4.142A5.554 5.554 0 0 0 47.568 180h104.865c1.528 0 2.988-.63 4.039-1.743a5.594 5.594 0 0 0 1.52-4.141zM79.98 52.34c0-11.668 9.456-21.161 21.079-21.161s21.079 9.493 21.079 21.161v17.73H79.98V52.34zM53.437 168.821l4.6-87.571h10.806v6.757c0 3.087 2.493 5.59 5.568 5.59s5.568-2.503 5.568-5.59V81.25h42.157v6.757c0 3.087 2.493 5.59 5.568 5.59s5.568-2.503 5.568-5.59V81.25h8.688l4.601 87.571H53.437z'></path>
                        </g>
                      </svg>
                    </div>
                    <h5 className='mt-3 text-black'>BEGINNING OF SALES</h5>
                    <p className='mt-4 text-black '>06 February 2025</p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class='home-steps  py-4'>
          <div class='container-lg container-fluid'>
            <div class='section-title text-center mt-4 mb-5 z-1'>
              <h5 class='text-uppercase text-maincolor position-relative'>
                <span class='p-2 bg-title px-5'>
                  10 steps closer to 1446 Hajj
                </span>
              </h5>
            </div>
          </div>

          <div style={{ background: 'rgb(23 36 78)' }}>
            <div class='container-lg container-fluid home-steps-container z-1 steps_animation'>
              <div class='row'>
                <div class='col-12 col-md-11'>
                  <div class='top-line'></div>
                  <div class='bottom-line'></div>
                  <div class='steps-curve d-none d-xl-block'>
                    <svg
                      version='1.1'
                      class='icon-flip-x'
                      id='svg_curve'
                      x='0px'
                      y='0px'
                      viewBox='0 0 172.1 328.6'
                    >
                      <path
                        id='path'
                        d='M0,1c94.5,0,170.9,72.3,170.9,162.8c0,90.5-76.3,163.8-170.9,163.8'
                      ></path>
                    </svg>
                  </div>
                  <div class='home-steps-box-container d-xl-block d-flex pe-5 pe-xl-0 ms-5 ms-xl-0'>
                    <div class='d-flex justify-content-between my-4 steps1_5'>
                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={user} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>01</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              CREATE AN ACCOUNT
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={file} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>02</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              UPLOAD YOUR DOCUMENTS
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={clipboard} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>03</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              FILL THE APPLICATION
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={check} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>04</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              APPLICATION VERIFICATION
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={favourite} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>05</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              SELECT PREFERRED CATEGORY
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class='bottom-line-mob d-xl-none'></div>
                    </div>
                    <div class='d-flex flex-xl-row-reverse justify-content-between steps6_10 pt-xl-4 my-4'>
                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={payment} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>06</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              TOP UP EWALLET
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={favourite} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>07</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              BROWSE SERVICE PROVIDER
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={thumb} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>08</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              SELECT YOUR PREFERRED PACKAGE
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={calandar} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>09</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              BOOK YOUR PACKAGE
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class='text-center home-step-item'>
                        <img src={circle} width='38' height='38' alt='' />
                        <div class='box rounded-2 px-3 py-4 mt-xl-4 mb-4 mb-xl-0'>
                          <img src={fvfile} width='92' height='92' alt='' />
                          <div class='d-flex align-items-center mt-2'>
                            <h3 class='main-title text-maincolor me-2'>10</h3>
                            <h6 class='main-title text-maincolor text-start'>
                              REVIEW ITINERARY
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div class='bottom-line-mob d-xl-none'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HajjDetail
