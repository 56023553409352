import React from "react";
import hajj1 from '../../Images/Hajj/hajj1.jpg'
import hajj2 from '../../Images/Hajj/hajj2.jpg'
import hajj3 from '../../Images/Hajj/hajj3.jpg'
import hajj4 from '../../Images/Hajj/hajj4.jpg'
import hajj5 from '../../Images/Hajj/hajj5.jpg'
import hajj6 from '../../Images/Hajj/hajj6.jpg'
import bgimage from '../../Images/bg1.jpg'

function HajjTop() {
  return (
    <div className='container my-4 hajj-new-top'>
          <div className='row'>
            <div className='col-md-4 col-sm-12 col-lg-4 '>
              <div className='tq-follow-boxes justify-content-center d-flex position-relative w-100'>
                <div className='tq-follow-box  d-flex flex-column'>
                  <a >
                    <img
                      className='img-fluid '
                      src={hajj1}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                  <a >
                    <img
                      className='img-fluid '
                      src={hajj2}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                </div>
                <div className='tq-follow-box tq-mt-60 d-flex flex-column'>
                  <a >
                    <img
                      className='img-fluid '
                      src={hajj3}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                  <a >
                    <img
                      className='img-fluid '
                      src={bgimage}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 col-lg-4 text-center position-relative'>
              <div className='svg-container position-relative d-flex justify-content-center align-items-center'>
                <svg
                  style={{ width: 'auto', height: '100%' }}
                  className='fill-theme'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 571 568'
                >
                  <g>
                    <path d='M0,285c0-0.67,0-1.33,0-2c8.61-5.65,15.18-13.46,21.86-21.08c12.95-14.78,24.65-30.67,38.92-44.27c12.39-11.81,26.05-21.27,43.81-23.07c5.21-0.53,7.8-3.85,8.14-9.17c0.14-2.32,0-4.67,0.07-7c0.61-20.09,8.49-36.79,23.69-49.91c13.47-11.63,29.32-16.72,47-16.55c8.94,0.08,11.26-1.9,12.49-10.58c1.22-8.56,4.35-16.37,9-23.63c10.24-15.99,24.68-27.83,38.95-39.88C258.17,25.84,273.4,14.89,285,0c0.33,0,0.67,0,1,0c6.55,8.74,14.71,15.85,22.97,22.88c15.09,12.85,31.11,24.65,44.7,39.18c10.15,10.85,18.56,22.71,21.11,37.84c1.86,11.03,3.11,11.91,14.23,12.05c22.71,0.29,41.49,8.96,55.43,27.06c10.02,13.02,14.41,27.95,13.81,44.42c-0.26,7.06,2.42,10.55,8.64,11.21c13.63,1.46,25.04,7.58,35.44,16.05c16.03,13.05,28.27,29.59,41.62,45.12c8.35,9.71,16.32,19.82,27.04,27.18c0,0.67,0,1.33,0,2c-3.95,2-6.83,5.29-9.91,8.32c-14.07,13.8-25.61,29.82-38.79,44.4c-15.6,17.24-31.93,33.31-56.87,35.93c-4.4,0.46-6.9,3.84-7.16,8.41c-0.14,2.49,0.01,5-0.07,7.5c-0.6,20.1-8.54,36.79-23.69,49.92c-13.73,11.91-29.96,16.89-48,16.59c-7.18-0.12-10.55,2.32-11.23,8.56c-1.41,12.92-7.15,23.81-15.07,33.76c-9.61,12.09-21.44,21.85-33.15,31.75c-14.23,12.03-29.54,22.9-41.07,37.86c-0.33,0-0.67,0-1,0c-6.7-8.83-14.98-16.06-23.36-23.19c-15.09-12.85-31.09-24.66-44.67-39.22c-10.99-11.78-19.64-24.78-21.33-41.43c-0.53-5.23-3.87-7.84-9.15-8.09c-1.83-0.09-3.67,0.06-5.5-0.01c-27.59-1.15-48.35-13.34-61.23-38.08c-5.52-10.6-7.38-21.98-7.04-33.87c0.19-6.69-2.56-10.1-8.63-10.75c-13.62-1.47-25.03-7.59-35.44-16.06c-16.04-13.04-28.3-29.56-41.61-45.12C18.72,302.43,10.67,292.41,0,285z' />
                  </g>
                </svg>

                
                <div
                style={{ flexWrap: 'wrap' }}
                className='hajj-links-2 position-absolute text-center'
              >
                <h2 className=' fw-bold text-white  mb-0'>
                  HAJJ 2025
                </h2>
                <div>
                  <a
                    className='nav-link small'
                    href='https://chat.whatsapp.com/LM2Eu95JBVtBUCZARdWAad'
                    target='blank'
                  >
                    <svg
                      fill='#279b3e'
                      height='15px'
                      width='15px'
                      viewBox='0 0 308 308'
                      stroke='#279b3e'
                    >
                      <g>
                        <path
                          d='M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
                    c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
                    c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
                    c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
                    c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
                    c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
                    c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
                    c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
                    c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
                    C233.168,179.508,230.845,178.393,227.904,176.981z'
                        />
                        <path
                          d='M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
                    c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
                    c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
                    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
                    l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
                    c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
                    C276.546,215.678,222.799,268.994,156.734,268.994z'
                        />
                      </g>
                    </svg>{' '}
                    JOIN HAJJ WHATSAPP GROUP
                  </a>
                </div>
                </div>
              </div>
            </div>
            <div className='col-md-4 col-sm-12 col-lg-4 '>
              <div className='tq-follow-boxes justify-content-center d-flex position-relative w-100'>
                <div className='tq-follow-box tq-mt-60 d-flex flex-column'>
                  <a >
                    <img
                      className='img-fluid w-100'
                      src={hajj4}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                  <a >
                    <img
                      className='img-fluid w-100'
                      src={hajj3}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                </div>
                <div className='tq-follow-box d-flex flex-column'>
                  <a >
                    <img
                      className='img-fluid w-100'
                      src={hajj5}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                  <a >
                    <img
                      className='img-fluid w-100'
                      src={hajj6}
                      alt=''
                      loading='lazy'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
}
export default HajjTop;